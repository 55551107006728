@use '~/styles/mixins';
@use '~/styles/size';
@import '~/components/styles.scss';

.grid {
  display: flex;
  flex-direction: column;
  padding: $base-unit;
  height: 100%;

  &[data-loading='true'] {
    opacity: 50%;
  }

  header,
  footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  header {
    padding-bottom: $base-unit;
    position: relative;

    .title {
      @include font-bold;

      & div:nth-child(2) {
        color: $color-gunmetal;
        margin-top: 6px;
      }
    }

    &::after {
      position: absolute;
      content: '';
      display: block;
      bottom: 0;
      left: $n-base-unit;
      width: calc(100% + $base-unit * 2);
      height: 1px;
      background-color: $cool-grey;
    }
  }

  main {
    padding-top: $base-unit;
    display: flex;
    overflow-y: hidden;
    flex-direction: column;
    flex: 1;
    margin-right: $n-base-unit - $n-quarter-unit;

    .emptyMessage {
      @include font-bold;
      color: $color-gunmetal;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      img {
        @include mixins.filter('secondary');
        height: 40px;
      }
    }
  }

  footer {
    padding-top: $half-unit;

    &.flexColumn {
      flex-direction: column;
      align-items: start;
    }

    .explosionCoefficient {
      display: flex;
      flex-direction: column;
      align-items: start;

      .subtitle {
        @include mixins.subtitle;
        margin-bottom: size.$quarter;
      }
    }

    .actions {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: stretch;
      justify-content: space-between;
      padding-top: $base-unit;

      .leftButtonsContainer {
        display: flex;
        flex-direction: row;
        align-items: center;

        div:not(:first-child) {
          margin-left: size.$half;
        }
      }
    }
  }
}

.bomWidget {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: $text-grey;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: $regular-font-family;
    font-size: $big-font-size;
    padding-bottom: $half-unit + $quarter-unit;

    .quoteName {
      font-weight: bold;
      color: $text-grey;
      padding-bottom: $quarter-unit;
    }

    .quoteNumber {
      font-weight: bold;
      color: $color-gunmetal;
    }
  }

  .lineBlock {
    height: 1px;
    background-color: $cool-grey;
    margin: $zero $n-base-unit $zero $n-base-unit;
    z-index: 99;
  }

  .content {
    height: 100%;

    .itemGroupList {
      display: flex;
      flex-direction: column;

      .itemGroup {
        @include widget-scrollable;
        display: flex;
        flex-direction: column;

        .itemGroupName {
          font-family: $regular-font-family;
          font-size: $small-font-size;
          font-weight: bold;
          margin-top: 17px;
          color: $color-gunmetal;
        }

        .itemList {
          font-family: $regular-font-family;
          font-size: $regular-font-size;
          .itemBlock {
            .itemComponent {
              .size {
                text-align: center;
              }
            }

            .row {
              height: 32px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-top: $half-unit;
              gap: $half-unit;
              flex-direction: row;

              .column {
                flex-direction: column;
                flex-basis: 100%;
                flex-grow: 0;
                flex-shrink: 0;
              }
              .arrow {
                flex-basis: 16px;

                img {
                  transition: transform $animation-time ease-out;
                  cursor: pointer;
                }

                &.open {
                  img {
                    transform: rotate(90deg);
                  }
                }
              }

              .image {
                flex-basis: 32px;

                img {
                  width: 32px;
                  height: 32px;
                }
              }

              .itemNumber {
                flex-basis: 55px;
                text-align: left;
              }

              .description {
                flex: 1;
                text-align: left;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                padding-left: 5px;
              }

              .size {
                flex-basis: 70px;
              }

              .quantity {
                flex-basis: 25px;
                text-align: right;
              }

              .icon {
                flex-basis: $base-unit;
                padding-top: $quarter-unit;
              }

              .price {
                flex-basis: 115px;
                text-align: right;
                font-weight: bold;

                .oldPrice {
                  font-size: 11px;
                  margin-right: $half-unit;
                  text-decoration: line-through;
                }
              }
            }

            .totalBlock {
              min-height: 28px;
              background-color: #37c18e;
              padding: $zero $half-unit;
              margin-right: $base-unit - $quarter-unit;

              .totalRow {
                color: $color-white;
                font-family: $regular-font-family;
                font-size: $regular-font-size;
                font-weight: 600;

                .details {
                  flex: 1;
                }
              }
            }

            .servicesBlock {
              overflow: hidden;
              transition: max-height $animation-time ease-in-out;
              height: auto;
              max-height: 600px;

              &.collapsed {
                max-height: 0px;
              }
            }
          }
        }
      }
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: $base-unit + $half-unit;
  }

  .hidden {
    visibility: hidden;
  }
}

.HTMLTooltip {
  & > div:first-child {
    margin-right: 0px !important;
  }
}
.toolTip {
  min-width: 40px;
  min-height: 30px;
  padding: $half-unit;
  border-radius: 2px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.25);
  background-color: $color-white;
  font-family: $regular-font-family;
  font-size: $regular-font-size;
  color: $text-grey;
  margin-left: 0;
}
